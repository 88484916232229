import { createApp, reactive } from "petite-vue";
import io from "socket.io-client";

const isHttps = location.protocol.startsWith("https");
const wsUrl = `${isHttps ? "wss" : "ws"}://${location.host}`;

const state = reactive({
    apps: null,

    connect() {
        const socket = io(wsUrl, {
            path: "/socket.io",
            protocols: ["websocket"],
        });

        socket.on("connect", () => console.log("connected via socket io"));

        socket.on("status", s => {
            if (Array.isArray(s)) {
                this.apps = s;
                return;
            } else if (!this.apps) {
                return;
            }

            console.log("Status update: ", s);

            const existsInApps = !!this.apps.find(entry => entry.app === s.app);

            if (existsInApps) {
                this.apps.map(a => a.app === s.app ? s : a);
            } else {
                this.apps.push(s);
            }
        });

        document.addEventListener("beforeunload", () => {
            try {
                socket.disconnect();
            } catch (err) {}
        });
    },
});

state.connect();

createApp({
    state,
}).mount("#app");

Array.from(document.querySelectorAll(".hidden")).forEach((n) => n.classList.remove("hidden"));
